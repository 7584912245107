export const column = [
  { key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 120 },
  {
    key: '3', headerName: 'Asset Group', field: 'asset_group_name', hide: false, width: 250
  },
  {
    key: '2', headerName: 'Asset Spare', field: 'processedAssetSpares', hide: false, width: 800
  },

  // { key: '4', headerName: 'Category', field: 'auditcategory_name', hide: false, width: 160 },
  // { key: '5', headerName: 'Status', field: 'status', hide: false, width: 100 },
];
