import { format, parseISO } from "date-fns";
export const column = [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 70 },
  {
    key: "2",
    headerName: "Ticket No",
    field: "service_number",
    hide: false,
    width: 200,
  },
  {
    key: "3",
    headerName: "Outlet Name",
    field: "outlet_code",
    hide: false,
    width: 230,
  },
  {
    key: "4",
    headerName: "ORL Name",
    field: "orl_name",
    hide: false,
    width: 150,
  },
  {
    key: "5",
    headerName: "ORL Number",
    field: "contact_no",
    hide: false,
    width: 150,
  },
  {
    key: "6",
    headerName: "Asset Group",
    field: "asset_group",
    hide: false,
    width: 180,
  },
  { key: "7", headerName: "Asset", field: "asset", hide: false, width: 180 },
  {
    key: "8",
    headerName: "Service For",
    field: "service_for",
    hide: false,
    width: 150,
  },
  {
    key: "9",
    headerName: "Ticket Status",
    field: "ticket_status",
    hide: false,
    width: 180,
  },
  {
    key: "10",
    headerName: "Creation Date",
    field: "date",
    hide: false,
    width: 180,
    valueGetter: (params) => format(parseISO(params.row.date), "dd-MM-yyyy"),
  },
  {
    key: "11",
    headerName: "Ageing Days",
    field: "aging_days",
    hide: false,
    width: 180,
  },
];
