export const column = [
  { key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 150 },
  // { key: '2', headerName: 'Division ID', field: 'id', hide: false, width: 250 },
  { key: '3', headerName: 'License Name', field: 'name', hide: false, width: 350 },
  { key: '3', headerName: 'Cost', field: 'price', hide: false, width: 150 },
  { key: '3', headerName: 'Penalty Amount', field: 'finepercentage', hide: false, width: 150 },
  { key: '3', headerName: 'Lead Days', field: 'lead_days', hide: false, width: 150 },
  { key: '4', headerName: 'Status', field: 'status', hide: false, width: 250 },
  
];
