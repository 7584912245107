import React ,{useEffect} from 'react';
import {useNavigate} from 'react-router';
import CustomTable from '../../../components/CustomTable';
import {column} from './column';
import {getOFREntry} from '../../../@app/subMaster/subMasterSlice';
import {useDispatch, useSelector } from 'react-redux';

export default function OFREntry({setTopTitle}) {
  setTopTitle('OFR Entry Details');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const handleEditClick = (data) => { 
    if(data.entrystatus == 'Partially Completed'){
      navigate('/OFREntry/editForm', {
        state: {...data,outlet_id:data?.outlet_id
        },      
      });
    }
    else
    {
      navigate('/OFREntry/addForm', {
        state: {...data,outlet_id:data?.outlet_id
        },      
      });
    }
  };

  const { type, userData } = useSelector((state) => state.auth);
  useEffect(() => {
    if (type === 1)
      dispatch(getOFREntry({ path: "get-OFR-Entry", data: {} }));
    else
      dispatch(
        getOFREntry({
          path: "get-OFR-Entry",
          data: { employee: userData.data?.id }
        })
      );
  }, []); 

  const {
    getOFREntryResponse: { data: dataSource },
    gettingOFREntry
  } = useSelector((state) => state.subMaster);

  return (
    <CustomTable
      loading={gettingOFREntry}
      dataSource={dataSource}
      column={column}    
      handleEditClick={handleEditClick}
     // onClickAdd={onClickAdd}
      title={"OFR Entry Details"}         
    />
  );
}
