const issueTypes = [{
  id: '1',
  name: 'Combo issue'
},
{
  id: '2',
  name: 'Open Bill issue'
},
{
  id: '3',
  name: 'EDC payment issue'
},
{
  id: '4',
  name: 'User Id Unlock'
},
{
  id: '5',
  name: 'Outlet to outlet transfer – FG/Beverage'
},
{
  id: '6',
  name: 'Payment mode change'
},
{
  id: '7',
  name: 'Stock issue'
},
{
  id: '7',
  name: 'Transfer in issue'
},
{
  id: '8',
  name: 'Others'
},
]




export default issueTypes;