// export const column = [
//   { key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80 },
//   { key: '2', headerName: 'Outlet Name', field: 'Outlet_Name', hide: false, width: 300 },
//   { key: '3', headerName: 'License', field: 'license', hide: false, width: 300 },
//   { key: '3', headerName: 'License Reg no', field: 'license_reg_no', hide: false, width: 180 },
//   { key: '3', headerName: 'Start Date', field: 'license_start_date', hide: false, width: 180 },
//   { key: '3', headerName: 'End Date', field: 'license_end_date', hide: false, width: 180 },
//   { key: '3', headerName: 'Days to go', field: 'Days to go', hide: false, width: 180 },
//   { key: '4', headerName: 'Status', field: 'status', hide: false, width: 200 },
// ];


export const column = [
  { key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 120 },
  { key: '2', headerName: 'Outlet', field: 'outlet_name', hide: false, width: 250 },
  { key: '3', headerName: 'License Register No', field: 'license', hide: false, width: 750 },
  //{ key: '4', headerName: 'License', field: 'get_license', hide: false, width: 750 },


];