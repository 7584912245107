import React from "react";
import { format } from 'date-fns';
import { entryStatus } from "../../../components/formComponents/CommonFunctions";
import { Badge } from "antd";
function getEntryDate(params) {
  return `${format(new Date(params.row.created_at), 'dd-MM-yyyy')}`;
}

export const baseColumns = [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 80 },
  {
    key: "2",
    headerName: "Outlet Code",
    field: "outlet_code",
    hide: false,
    width: 100
  },
  {
    key: "3",
    headerName: "Outlet Name",
    field: "outlet_name",
    hide: false,
    width: 250,
  },
 {
    key: "4",
    headerName: "Date",
    field: "created_at",
    hide: false,
    width: 100,  
    valueGetter: (params) => {
        return getEntryDate(params);
    }
},
{
  key: "5",
  headerName: "Status", // Keep the original header name
  field: "entrystatus",
  hide: false,
  width: 150,
  renderCell: (params) => {
    return (
      <Badge
        style={{ backgroundColor: entryStatus(params.row.entrystatus) }}
        count={params.row.entrystatus}
      ></Badge>
    );
  }
 
},
];

export const generateColumns = (data) => {
  
  if (!data) {
    return baseColumns;
  }


  const materialDescriptions = new Set();

  data.forEach(row => {
    if (row.material_group && Array.isArray(row.material_group)) {
      row.material_group.forEach(group => {
        if (group.description) {
          group.description.forEach(desc => {
            if (desc.checklist_name) {
              materialDescriptions.add(desc.checklist_name);
            }
          });
        }
      });
    }
  });
  
  const materialColumns = [];
  let columnIndex = 6;  
  materialDescriptions.forEach(description => {
    materialColumns.push({
      key: `${columnIndex}_AM`,
      headerName: `${description} (AM)`,
      field: `${description}_AM`,
      hide: false,
      width: 150,
      valueGetter: (params) => {
        for (const matGroup of params.row.material_group) {
          if (matGroup.description) {
            for (const desc of matGroup.description) {
              if (desc.checklist_name === description) {
                 return desc.AMvalue || '';
              }
            }
          }
        }
        return ''; 
      }
    });
  
    materialColumns.push({
      key: `${columnIndex}_PM`, 
      headerName: `${description} (PM)`, 
      field: `${description}_PM`,
      hide: false,
      width: 150,
      valueGetter: (params) => {
        for (const matGroup of params.row.material_group) {
          if (matGroup.description) {
            for (const desc of matGroup.description) {
              if (desc.checklist_name === description) {              
                return desc.PMvalue && desc.AMvalue;             
              }
            }
          }
        }
        return ''; 
      }
    });
  
    columnIndex++;
  });
  
  return [...baseColumns, ...materialColumns];
}  


