//import React from 'react';
//import { Badge } from 'antd';
//import {colorName} from '../../../components/formComponents/CommonFunctions';
export const column = [
  { key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 180 },
  { key: '2', headerName: 'Range From', field: 'range_from', hide: false, width: 180 },
  { key: '3', headerName: 'Range To', field: 'range_to', hide: false, width: 180 },
  { key: '4', headerName: 'Level', field: 'level', hide: false, width: 250 },
  { key: '5', headerName: 'Amount', field: 'amount', hide: false, width: 150 },
  { key: '5', headerName: 'Status', field: 'status', hide: false, width: 150 },
  // {
  //   key: '6', headerName: 'Status', field: 'status', hide: false, width: 250,
  //   renderCell: (params) => {
  //     return (
  //       <Badge
  //         style={{ backgroundColor: colorName(params.row.status) }} count={params.row.status}>
  //       </Badge>
  //     );
  //   }
  // },
];
