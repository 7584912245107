export const column = [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 120 },

  {
    key: "3",
    headerName: "Asset No Sap",
    field: "asset_no_sap",
    hide: false,
    width: 300,
  },
  // {
  //   key: "4",
  //   headerName: "Asset Name Sap",
  //   field: "asset_name_sap",
  //   hide: false,
  //   width: 300,
  // },
  {
    key: "5",
    headerName: "Asset",
    field: "asset",
    hide: false,
    width: 300,
  },
  {
    key: "6",
    headerName: "Asset Group",
    field: "asset_group",
    hide: false,
    width: 350,
  },
  {
    key: "7",
    headerName: "Company Code",
    field: "company_code",
    hide: false,
    width: 350,
  },

  { key: "8", headerName: "Status", field: "status", hide: false, width: 200 },
];
