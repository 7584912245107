import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOFRReport,
  getAllMappedOutlet
} from "../../../@app/subMaster/subMasterSlice";
import CustomTable from "../../../components/CustomTable";
import { generateColumns } from "./column";
import { Row, Col, Form, DatePicker, Card, Select } from "antd";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { format } from "date-fns";

export default function OFREntryReport({ setTopTitle }) {
  setTopTitle("OFR Entry Report");
  const { Option } = Select;
  const dispatch = useDispatch();

  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [dropdownoutlet, setDropdownoutlet] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const { type, userData } = useSelector((state) => state.auth);
  const empId = userData.data?.id;

  const {
    gettingOFRReport,
    getOFRReportResponse: { data: dataSource }
  } = useSelector((state) => state.subMaster);

  const { gettingAllMappedOutlet } = useSelector((state) => state.subMaster);

  const { handleSubmit } = useForm();

  // Fetch all mapped outlets
  useEffect(() => {
    const fetchData = async () => {
      let result;
      if (type == 1) {
        result = await dispatch(
          getAllMappedOutlet({ path: "get-all-mapped-outlet", data: {} })
        );
      } else {
        result = await dispatch(
          getAllMappedOutlet({
            path: "get-all-mapped-outlet",
            data: { employee: empId }
          })
        );
      }

      if (result) {
        const options = result.data.map((item) => ({
          key: item.outlet_code,
          value: item.outlet_code,
          label: `${item.outlet_code}-${item.name}`
        }));
        options.unshift({
          key: "select_all",
          value: "select_all",
          label: "Select All"
        });
        options.unshift({
          key: "unselect_all",
          value: "unselect_all",
          label: "Unselect All"
        });
        setDropdownoutlet(options);
      }
    };

    fetchData();
  }, [type, empId, dispatch]);

  const columns = generateColumns(dataSource);

  const handleDownload = () => {
    const csvContent = createCSVContent(dataSource);
    downloadCSV(csvContent);
  };

  function getEntryDate(createdAt) {
    return format(new Date(createdAt), "dd-MM-yyyy");
  }

  function createCSVContent(data) {
    const headers = [
      "Outlet Code",
      "Outlet Name",
      "Entry Date",
      "CheckList Type",
      "CheckList Name",
      "Temperature",
      "AM",
      "PM",
      "Status" // Added Status column
    ];
    const rows = [];
    data.forEach((row) => {
      let outletAdded = false;
      row.material_group.forEach((group) => {
        group.description.forEach((desc, index) => {
          const rowData = [
            !outletAdded ? row.outlet_code : "",
            !outletAdded ? row.outlet_name : "",
            !outletAdded ? getEntryDate(row.created_at) : "",
            index === 0 ? group.check_list_type : "",
            desc.checklist_name,
            desc.temperature || "",
            desc.AMvalue || "",
            desc.PMvalue || "",
            !outletAdded ? row.entrystatus : ""
          ];
          rows.push(rowData);
          outletAdded = true;
        });
      });
    });

    let csvContent = headers.join(",") + "\n";
    rows.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });
    return csvContent;
  }

  function downloadCSV(csvContent) {
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "checklist_data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handleFormSubmit = () => {
    if (startDate) {
      const [startSelectedDate, endSelectedDate] = startDate.map((date) =>
        dayjs(date).format("YYYY-MM-DD")
      );

      const requestData = {
        startDate: startSelectedDate,
        endDate: endSelectedDate,
        outletid: selectedOutlets
      };

      if (type === 1) {
        dispatch(getOFRReport({ path: "get-OFR-report", data: requestData }));
      } else {
        dispatch(
          getOFRReport({
            path: "get-OFR-report",
            data: { ...requestData, employee: empId }
          })
        );
      }
    } else {
      alert("Please select a date range.");
    }
    handleSubmit();
  };

  return (
    <>
      <Card>
        <Row gutter={[25, 0]}>
          <Col md={5} xs={24} span={24}>
            <Form.Item label="Outlet Code">
              <Select
                style={{ width: "300px" }}
                placeholder="Select"
                loading={gettingAllMappedOutlet}
                maxTagCount={0}
                showSearch
                mode="multiple"               
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={selectedOutlets}
                onChange={(newSelectedValues) => {
                  if (newSelectedValues.includes("select_all")) {
                    setSelectedOutlets(
                      dropdownoutlet
                        .filter(
                          (option) =>
                            option.value !== "select_all" &&
                            option.value !== "unselect_all"
                        )
                        .map((option) => option.value)
                    );
                  } else if (newSelectedValues.includes("unselect_all")) {
                    setSelectedOutlets([]);
                  } else {
                    setSelectedOutlets(newSelectedValues);
                  }
                }}
              >
                {dropdownoutlet.map((item) => (
                  <Option key={item.key} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={5} xs={24} span={24}>
            <Form.Item name="month" label="Date Filter">
              <DatePicker.RangePicker
                format="DD-MM-YYYY"
                value={startDate}
                onChange={(dates) => setStartDate(dates)}
                style={{ width: "300px" }}
              />
            </Form.Item>
          </Col>
          <Col md={5} xs={24} span={24}>
            <Form.Item>
              <button
                onClick={handleFormSubmit}
                style={{
                  background: "#34b1aa",
                  color: "#ffffff",
                  margin: "15px"
                }}
                className="btn btn-primary"
              >
                Filter
              </button>
              <button
                style={{
                  background: "#34b1aa",
                  color: "#ffffff",
                  margin: "15px"
                }}
                className="btn btn-primary"
                onClick={handleDownload}
              >
                Download CSV
              </button>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <CustomTable
        loading={gettingOFRReport}
        dataSource={dataSource ?? []}
        column={columns}
        hideActionBtn={true}
        title={"Deviation Report"}
      />
    </>
  );
}
