export const column = [
  { key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 120 },
  { key: '2', headerName: 'Definitions', field: 'def_title', hide: false, width: 260 },
  { key: '3', headerName: 'Definitions List', field: 'def_list_name', hide: false, width: 200 },
  //{ key: '4', headerName: 'State', field: 'state_name', hide: false, width: 260 },
  {
    key: '4', headerName: 'Status', field: 'status', hide: false, width: 105, 
  
  },
];
