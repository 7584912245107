export const column = [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 60 },
  {
    key: "3",
    headerName: "Audit Number",
    field: "id",
    hide: false,
    width: 150,
  },
  {
    key: "4",
    headerName: "Outlet Name",
    field: "outlet_name",
    hide: false,
    width: 250,
  },
  {
    key: "5",
    headerName: "Audit Agent Name",
    field: "agentName",
    hide: false,
    width: 205,
  },
  {
    key: "6",
    headerName: "Submission Date",
    field: "submission_date",
    hide: false,
    width: 205,
  },
  {
    key: "7",
    headerName: "Actual Score",
    field: "total_mark",
    hide: false,
    width: 170,
  },
  { key: "8", headerName: "Rank", field: "rank", hide: false, width: 170 },
];
