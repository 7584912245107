import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Col,
  Row,
  Form,
  Collapse,
  Input,
  Descriptions,
  Radio
} from "antd";
import { useNavigate } from "react-router-dom";
import apis from "../../../api/stateAPI";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import ConfirmOnExit from "../../../components/confirmOnExit/ConfirmOnExit";
import messageToast from "../../../components/messageToast/messageToast";
import { DoubleRightOutlined } from "@ant-design/icons";

function OFREntryForm() {
  const { state } = useLocation(); 
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    material_group: []
  });
  const [showDialog, setShowDialog] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const userData = useSelector((state) => state.auth);
  const handleClickBack = () => {
    navigate("/OFREntry");
  };

  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);
  const { handleSubmit } = useForm();

  useEffect(() => {
    if (state?.entrystatus == 'Partially Completed') { 
      const defTitleData = state?.material_group || {}; // Use state data    
      const material_group = Object.values(defTitleData).map((group) => {
        const description = group.description.map((item) => {
          return {
            checklist_name: item.checklist_name,
            temperature: item.temperature,
            AMvalue: item.AMvalue || "", // Handle AMvalue
            PMvalue: item.PMvalue || ""  // Handle PMvalue
          };
        });
  
        return {
          check_list_type: group.check_list_type,
          description: description // Set the processed description array
        };
      });
  
      setFormData({ ...state, material_group });
    }
  }, [state?.entrystatus, state?.material_group]); // Only run when state.entrystatus or state.material_group changes
  
  const handleRadioChangeAM = (i, j, event) => {
    const { name, value } = event.target;
    let data = [...formData.material_group];
    data[i].description[j][name] = value;
    setFormData((fd) => ({ ...fd, material_group: data }));    
  };

  const handleScoreChangeAM = (i, j, event) => {
    const { name, value } = event.target;
    let data = [...formData.material_group];
    data[i].description[j][name] = value;
    setFormData((fd) => ({ ...fd, material_group: data }));
  };

  const handleRadioChangePM = (i, j, event) => {
    const { name, value } = event.target;
    let data = [...formData.material_group];
    data[i].description[j][name] = value;
    setFormData((fd) => ({ ...fd, material_group: data }));
  };

  const handleScoreChangePM = (i, j, event) => {
    const { name, value } = event.target;
    let data = [...formData.material_group];
    data[i].description[j][name] = value;
    setFormData((fd) => ({ ...fd, material_group: data }));
  };

  const onFinish = () => {
    let outlet_id = state?.outlet_id;
    setShowDialog(false);
    setApiError("");
    let newStatus = "2";
    let entry_val = formData.material_group;
    entry_val.map((val) => {
      let list_val = val.description;    
      list_val.map((val2) => {              
        if (val2.AMvalue === null || val2.PMvalue === null || val2.AMvalue === '' || val2.PMvalue === '') {
          newStatus = "4"; 
        }
      });
    });
    let submitted = {
      ...formData,
      updated_by: userData.userData.data?.id ?? "0",
      outlet_id: outlet_id,
      newstatus: newStatus
    };

    // Make the API call only once after validation
    setLoading(true);
    apis
      .addOFREntry(submitted)
      .then((res) => {
        if (res.data.status === 200) {
          setTimeout(() => {
            messageToast({
              message: res.data.statusText,
              statusText: res.data.statusText,
              title: "OFR Entry Submitted"
            });
            navigate("/OFREntry");
          }, 2000);
        } else if (res.data.status === 300) {
          messageToast({
            message: res.data.statusText,
            statusText: res.data.statusText,
            title: "OFR"
          });
          setLoading(false);
        } else {
          setApiError(res?.data?.message ?? "Something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        setApiError(err.message ?? "Something went wrong");
        setLoading(false);
      });
  };

  return (
    <>
      <Card>
        <ConfirmOnExit showModel={showDialog} />
        <Row style={{ justifyContent: "center" }}>
          <Col span={24}>
            <Form
              onFieldsChange={() => setShowDialog(true)}
              name="basic"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete="off"
            >
              <Row gutter={[15, 0]}>
                <Col span={24}>
                  {formData.material_group.length > 0
                    ? formData.material_group.map((cat, i) => (
                        <Collapse
                          defaultActiveKey={i}
                          accordion
                          key={i}
                          style={{
                            width: "1000px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            background: "#e0ee86"
                          }}
                          expandIcon={({ isActive }) => (
                            <DoubleRightOutlined
                              style={{ color: "#000000" }}
                              rotate={isActive ? 90 : 0}
                            />
                          )}
                          className="d-flex flex-column"
                        >
                          <Panel
                            header={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  color: "#000000",
                                  fontWeight: "bold",
                                  justifyContent: "space-between"
                                }}
                              >
                                <label>{cat.check_list_type}</label>
                              </div>
                            }
                            key={i}
                          >
                            {cat.description !== undefined
                              ? cat.description.map((item, j) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between"
                                    }}
                                    key={j}
                                  >
                                    <Descriptions>
                                      <Descriptions.Item
                                        style={{
                                          width: "350px",
                                          height: "3px"
                                        }}
                                        className={`custom-background ${
                                          show ? "show" : ""
                                        }`}
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            color: "#3706d2",
                                            fontWeight: "bold",
                                            justifyContent: "space-between"
                                          }}
                                        >
                                          {item.checklist_name}
                                        </span>
                                      </Descriptions.Item>
                                      <Descriptions.Item
                                        style={{
                                          width: "200px",
                                          height: "3px"
                                        }}
                                        className={`custom-background ${
                                          show ? "show" : ""
                                        }`}
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            color: "#0ca797",
                                            fontWeight: "bold",
                                            justifyContent: "space-between"
                                          }}
                                        >
                                          {item.temperature}
                                        </span>
                                      </Descriptions.Item>
                                      <Descriptions.Item
                                        style={{
                                          width: "550px",
                                          height: "3px"
                                        }}
                                        className={`custom-background ${
                                          show ? "show" : ""
                                        }`}
                                      >
                                        <Form.Item
                                          style={{
                                            height: "3px",
                                            flexDirection: "row"
                                          }}
                                          name={`value_${i}_${j}`}
                                          rules={[
                                            {
                                              pattern: /^[0-9]+$/g,
                                              message: "Invalid quantity"
                                            }
                                          ]}
                                        >                                       
                                          {item.temperature === "On-going" ||
                                          item.temperature === null ? (
                                            <>
                                              <span
                                                style={{
                                                  color: "#6f0468",
                                                  fontWeight: "bold"
                                                }}
                                              >
                                                AM
                                              </span>

                                              <Radio.Group
                                                buttonStyle="solid"
                                                size="middle"
                                                name="AMvalue"
                                                onChange={(e) =>
                                                  handleRadioChangeAM(i, j, e)
                                                }
                                                value={item.AMvalue}
                                              >
                                                <Radio.Button
                                                  className="active"
                                                  value="Yes"
                                                >
                                                  Yes
                                                </Radio.Button>
                                                <Radio.Button
                                                  className="in-active"
                                                  value="No"
                                                >
                                                  No
                                                </Radio.Button>
                                              </Radio.Group>
                                              <span
                                                style={{
                                                  color: "#6f0468",
                                                  fontWeight: "bold"
                                                }}
                                              >
                                                PM
                                              </span>
                                              <Radio.Group
                                                buttonStyle="solid"
                                                size="middle"
                                                name="PMvalue"
                                                onChange={(e) =>
                                                  handleRadioChangePM(i, j, e)
                                                }
                                                value={item.PMvalue}
                                              >
                                                <Radio.Button
                                                  className="active"
                                                  value="Yes"
                                                >
                                                  Yes
                                                </Radio.Button>
                                                <Radio.Button
                                                  className="in-active"
                                                  value="No"
                                                >
                                                  No
                                                </Radio.Button>
                                              </Radio.Group>
                                            </>
                                          ) : (
                                            <>
                                              <span
                                                style={{
                                                  color: "#6f0468",
                                                  fontWeight: "bold"
                                                }}
                                              >
                                                AM
                                              </span>
                                              <Input
                                                className="mx-1 my-1"
                                                required="required"
                                                type="number"
                                                min="0"
                                                style={{ width: "70px" }}                                               
                                                onKeyDown={(e) => {                                                 
                                                  if (
                                                    !(
                                                      (e.key >= '0' && e.key <= '9') || 
                                                      e.key === 'Backspace' || 
                                                      e.key === 'Tab' || 
                                                      e.key === 'Enter' || 
                                                      e.key === 'ArrowLeft' || 
                                                      e.key === 'ArrowRight' || 
                                                      e.key === 'Delete'
                                                    )
                                                  ) {
                                                    e.preventDefault(); 
                                                  }
                                                }}
                                                onChange={(e) => {                                                
                                                  const value = e.target.value;
                                                  if (/^\d*$/.test(value)) {
                                                    handleScoreChangeAM(i, j, e); 
                                                  }
                                                }}
                                                value={item.AMvalue}
                                                name="AMvalue"
                                              />
                                              <span
                                                style={{
                                                  color: "#6f0468",
                                                  fontWeight: "bold"
                                                }}
                                              >
                                                PM
                                              </span>
                                              <Input
                                                className="mx-1 my-1"
                                                required="required"
                                                type="number"
                                                min="0"
                                                style={{ width: "70px" }}
                                                onKeyDown={(e) => {                                                 
                                                  if (
                                                    !(
                                                      (e.key >= '0' && e.key <= '9') || 
                                                      e.key === 'Backspace' || 
                                                      e.key === 'Tab' || 
                                                      e.key === 'Enter' || 
                                                      e.key === 'ArrowLeft' || 
                                                      e.key === 'ArrowRight' || 
                                                      e.key === 'Delete'
                                                    )
                                                  ) {
                                                    e.preventDefault(); 
                                                  }
                                                }}
                                                onChange={(e) => {                                                
                                                  const value = e.target.value;
                                                  if (/^\d*$/.test(value)) {
                                                    handleScoreChangePM(i, j, e);
                                                  }
                                                }}
                                                value={item.PMvalue}
                                                name="PMvalue"
                                              />
                                            </>
                                          )}
                                        </Form.Item>
                                      </Descriptions.Item>
                                    </Descriptions>
                                  </div>
                                ))
                              : null}
                          </Panel>
                        </Collapse>
                      ))
                    : "No Data"}
                </Col>
                <Col span={24}>
                  <Row
                    gutter={[15, 15]}
                    style={{ justifyContent: "center", marginTop: "20px" }}
                  >
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Form.Item
                        wrapperCol={{ offset: 8, span: 16, padding: "15px" }}
                      >
                        <Button
                          style={{ backgroundColor: "#34b1aa" }}
                          type="primary"
                          onClick={handleSubmit(onFinish)}
                          loading={loading}
                        >
                          {"Submit"}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item>
                        <Button onClick={handleClickBack}>Back</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[15, 15]} style={{ justifyContent: "center" }}>
                    <Col span={12} style={{ textAlign: "right" }}>
                      {typeof apiError === "object" ? (
                        Object?.values(apiError)?.map((e) => (
                          <div key={e?.[0]} className="text-danger">
                            {e?.[0]}
                          </div>
                        ))
                      ) : (
                        <div className="text-danger">{apiError}</div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default OFREntryForm;
