import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getOFRDayPlanMapping } from "../../../@app/subMaster/subMasterSlice";
import CustomTable from "../../../components/CustomTable";
import { column } from "./column";

export default function OFRDayPlanMappingMaster({ setTopTitle }) {

  useEffect(() => {
    setTopTitle("OFR Day Plan Mapping Master List");
  }, [setTopTitle]);

  const { type, userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    getOFRDayPlanMappingResponse: { data: dataSource },
    gettingOFRDayPlanMapping
  } = useSelector((state) => state.subMaster);
  const empId = userData.data?.id;
  
  const isSixDigits = (customerId) => /^\d{6}$/.test(customerId);
  const getRowStyle = (params) => {
    if (isSixDigits(params.data.customer_id)) {
      return { backgroundColor: "green" };
    }
    return null;
  };

  const onClickAdd = () => {
    navigate('/OFRDayPlanMappingMaster/addForm', {
      state: {}
    });
  };

  const handleEditClick = (data) => {
      navigate('/OFRDayPlanMappingMaster/editForm', {
      state: {...data, edit: true}
    });
  };

  const gridData = (dataSource ?? []).map((e) => {
    return {
      ...e
    };
  });

  useEffect(() => {
    if (type === 1)
      dispatch(
        getOFRDayPlanMapping({ path: "get-OFR-DayPlan-mapping", data: {} })
      );
    else
      dispatch(
        getOFRDayPlanMapping({
          path: "get-OFR-DayPlan-mapping",
          data: { employee: empId }
        })
      );
  }, []);

  return (
    <>           
      <CustomTable
        loading={gettingOFRDayPlanMapping}
        dataSource={gridData}
        handleEditClick={handleEditClick}
        onClickAdd={onClickAdd}
        column={column}
        getRowStyle={getRowStyle}
        title={"Day Plan Mapping Master List"}
      />
      ;
    </>
  );
}
