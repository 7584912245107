export const column = [
  { key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 130 },
  // { key: '2', headerName: 'Code', field: 'name', hide: false, width: 300 },
  { key: '3', headerName: 'AssetGroup', field: 'name', hide: false, width: 300 },
  {
    key: '4', headerName: 'Service For', field: 'servicefor_name', hide: false, width: 300
  },
  // { key: '5', headerName: 'AG AMC -- Covered', field: 'name', hide: false, width: 600 }
  { key: '5', headerName: 'Status', field: 'status', hide: false, width: 300 }

];