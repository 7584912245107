import React ,{useEffect} from 'react';
import {useNavigate} from 'react-router';
import CustomTable from '../../../components/CustomTable';
import {column} from './column';
import {getOFRMaster} from '../../../@app/subMaster/subMasterSlice';
import {useDispatch, useSelector } from 'react-redux';

export default function OFRMaster({setTopTitle}) {
  setTopTitle('OFR Master Details');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClickAdd = () => {
    navigate('/OFRMaster/addForm', {
      state: {}
    });
  };

  const handleEditClick = async (data) => {    
      navigate("/OFRMaster/editForm", {
            state: { ...data }
        });   
  };

  const { type, userData } = useSelector((state) => state.auth);
  useEffect(() => {
    if (type === 1)
      dispatch(getOFRMaster({ path: "get-OFR-Master", data: {} }));
    else
      dispatch(
        getOFRMaster({
          path: "get-OFR-Master",
          data: { employee: userData.data?.id }
        })
      );
  }, []);
 

  const {
    gettingOFRMaster,
    getOFRMasterResponse: {data: dataSource}
  } = useSelector((state) => {
    return state.subMaster;
  });

  return (
    <CustomTable
      loading={gettingOFRMaster}
      dataSource={dataSource}
      column={column}    
      handleEditClick={handleEditClick}
      onClickAdd={onClickAdd}
      title={"OFR Master Details"}               
    />
  );
}
