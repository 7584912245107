export const column = [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 120 },
  // { key: '2', headerName: 'code', field: 'code', hide: false, width: 190 },
  // {
  //   key: '3',
  //   headerName: 'Asset No in SAP',
  //   field: 'asset_no_sap',
  //   hide: false,
  //   width: 190
  // },
  {
    key: "4",
    headerName: "Asset Name in SAP",
    field: "asset_name_sap",
    hide: false,
    width: 200,
  },
  {
    key: "5",
    headerName: "Asset Group",
    field: "asset_group",
    hide: false,
    width: 200,
  },
  {
    key: "6",
    headerName: "Outlet Code",
    field: "outlet_code",
    hide: false,
    width: 200,
  },
  {
    key: "7",
    headerName: "Outlet Name",
    field: "outlet_name",
    hide: false,
    width: 200,
  },
  {
    key: "8",
    headerName: "Asset warranty end date ",
    field: "asset_warranty_end_date",
    hide: false,
    width: 200,
  },
  {
    key: "9",
    headerName: "Spare List",
    field: "spares_list",
    hide: false,
    width: 200,
  },
  { key: "5", headerName: "Status", field: "status", hide: false, width: 100 },
];
