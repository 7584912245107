export const column = [
  {
    key: "1",
    headerName: "S.No",
    field: "S.No",
    hide: false,
    width: 60,
  },
  {
    key: "2",
    headerName: "Module Id",
    field: "module_entry_id",
    hide: false,
    width: 100,
  },
  {
    key: "3",
    headerName: "Module Name",
    field: "module_name",
    hide: false,
    width: 260,
  },
  {
    key: "4",
    headerName: "Old Entry Details",
    field: "old_entry_data",
    hide: false,
    width: 560,
  }, 
  {
    key: "5",
    headerName: "Current Entry Details",
    field: "current_entry_data",
    hide: false,
    width: 560,
  },   
];


